import React from 'react';
import Layout from '../components/layout/Layout';
import Button from '../components/Button';


const Privacy = () => (
    <Layout>  
      <section className="pt-5">
        <div className="container mx-auto">
        <h2 className="text-3xl mx-5 font-semibold">Privacy Notice</h2>
        <div className="container mx-auto my-5 py-5 bg-gray-200 rounded-lg">
            <p className="mt-8 mb-2 mx-5 text-xl font-semibold">Effective Date: January 1, 2021</p>
            <p className="mb-5 mx-5 text-xl font-light">
            This privacy notice discloses the privacy practices for On The Block 
            Real Estate and our website; https://www.ontheblockrealestate.com and its Facebook 
            or other Social Media sites. This privacy notice applies solely to information 
            collected by this website, except where stated otherwise. It will notify you 
            of the following:<br/>
            • What information we collect;<br/>
            • With whom it is shared;<br/>
            • How it can be corrected;<br/>
            • How it is secured;<br/>
            • How policy changes will be communicated;<br/>
            • and How to address concerns over misuse of personal data.<br/>
            </p>

            <p className="mt-8 mb-2 mx-5 text-xl font-semibold">Information Collection, Use, and Sharing</p>
            <p className="mb-5 mx-5 text-xl font-light">
            We are the sole owners of the information collected on this site. We only have access to/collect 
            information that you voluntarily give us via email or other direct contact from you. We will not 
            sell or rent this information to anyone.<br/>
            We will use your information to respond to you, regarding the reason you contacted us. We will 
            not share your information with any third party outside of our organization, other than as necessary 
            to fulfill your request, e.g., to advise a home value or provide home buyer education.<br/>
            Unless you ask us not to, we may contact you via email in the future to tell you about specials, new products or services, or changes to this privacy policy.<br/>
            </p>

            <p className="mt-8 mb-2 mx-5 text-xl font-semibold">Your Access to and Control Over Information</p>
            <p className="mb-5 mx-5 text-xl font-light">
            You may opt out of any future contacts from us at any time. You can do the following at any time 
            by contacting us via the email address or phone number provided on our website:<br/>
            • See what data we have about you, if any.<br/>
            • Change/correct any data we have about you.<br/>
            • Have us delete any data we have about you.<br/>
            • Express any concern you have about our use of your data<br/>
            </p>

            <p className="mt-8 mb-2 mx-5 text-xl font-semibold">Registration</p>
            <p className="mb-5 mx-5 text-xl font-light">
            In order to use this website, a user must first complete the registration form as required. During registration a user is required to give certain information (such as name and email address). This information is used to contact you about the products/services on our site in which you have expressed interest.
            We request information from you on our order form. To best assist you in the purchase or sale of home including: address, length of time owning your home or property or other information.<br/>
            </p>

            <p className="mt-8 mb-2 mx-5 text-xl font-semibold">Sharing</p>
            <p className="mb-5 mx-5 text-xl font-light">
            We partner with another party to provide specific services. When the user signs up for these services, we will share names, or other contact information that is necessary for the third party to provide these services. These parties are not allowed to use personally identifiable information except for the purpose of providing these services.</p>

            <p className="mt-8 mb-2 mx-5 text-xl font-semibold">Security</p>
            <p className="mb-5 mx-5 text-xl font-light">
            We take precautions to protect your information. When you submit sensitive information via the website, your information is protected both online and offline.
            While we use encryption to protect sensitive information transmitted online, we also protect your information offline. Only employees who need the information to perform a specific job (e.g. billing or customer service) are granted access to personally identifiable information. The computers/servers on which we store personally identifiable information are kept in a secure environment.</p>

            <p className="mt-8 mb-2 mx-5 text-xl font-semibold">Links</p>
            <p className="mb-5 mx-5 text-xl font-light">
            This web site contains links to other sites. Please be aware that we are not responsible for the content or privacy practices of such other sites. We encourage our users to be aware when they leave our site and to read the privacy statements of any other site that collects personally identifiable information.</p>

            <p className="mt-8 mb-2 mx-5 text-xl font-semibold">Surveys & Contest</p>
            <p className="mb-5 mx-5 text-xl font-light">
            In order to use this website, a user must first complete the registration form as required. During registration a user is required to give certain information (such as name and email address). This information is used to contact you about the products/services on our site in which you have expressed interest.
            We request information from you on our order form. To best assist you in the purchase or sale of home including: address, length of time owning your home or property or other information.<br/>
            </p>

            <p className="mt-8 mb-2 mx-5 text-xl font-semibold">Notification of Changes</p>
            <p className="mb-5 mx-5 text-xl font-light">
            Whenever material changes are made to the privacy notice specify how you will notify consumers.</p>
        
            <p className="mt-8 mb-2 mx-5 text-xl font-semibold">Other Provisions as Required by Law</p>
            <p className="mb-5 mx-5 text-xl font-light">
            Numerous other provisions and/or practices may be required as a result of laws, international treaties, or industry practices. It is up to you to determine what additional practices must be followed and/or what additional disclosures are required. Please take special notice of the California Online Privacy Protection Act (CalOPPA), which is frequently amended and now includes a disclosure requirement for “Do Not Track” signals.
            If you feel that we are not abiding by this privacy policy, you should contact us immediately via telephone at 562)-972-1390 or via email at info@ontheblockrealestate.com.</p>

        </div>
        </div>
      </section>
    </Layout>
  );

export default Privacy;